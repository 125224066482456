<template>
  <b-card-actions action-collapse title="Daftar Teritori">
    <!-- ref="refreshCard" -->
    <!-- action-refresh
    @refresh="refreshStop('refreshCard')" -->
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-tambah variant="gradient-primary">
          <feather-icon icon="PlusIcon" class="mr-50" />
          Tambah
        </b-button>
        <b-modal v-model="showModalTambah" id="modal-tambah" cancel-variant="secondary" ok-title="Tambah"
          cancel-title="Batal" centered title="Form Tambah">
          <b-form>
            <b-form-group label="Provinsi" label-for="v-provinsi">
              <!-- <validation-provider #default="{ errors }" name="provinsi" :rules="{ required: true }"> -->
              <v-select v-model="form.provinsi" :reduce="(option) => option.value" label="text" :options="optprovinsi"
                @input="getkabkota()" />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
            </b-form-group>
            <b-form-group>
              <b-form-group label="Kabupaten Kota" label-for="v-kab_kota">
                <!-- <validation-provider #default="{ errors }" name="kab_kota" :rules="{ required: true }"> -->
                <v-select v-model="form.kab_kot_id" :reduce="(option) => option.value" label="text" :options="optkabko" />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
              </b-form-group>
            </b-form-group>
            <b-form-group>
              <label for="teritori">Nama Teritori</label>
              <b-form-input v-model="form.nama_teritori" label="Nama Teritori" />
            </b-form-group>
            <b-form-group>
              <label for="keterangan">Keterangan</label>
              <b-form-textarea v-model="form.keterangan" placeholder="Keterangan" rows="3" />
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="success" @click="submit">
                Tambah
              </b-button>
              <b-button size="sm" class="mr-1" variant="gradient-primary" @click="resetForm">
                Hapus isian
              </b-button>
              <b-button size="sm" variant="danger" @click="showModalTambah = false">
                Keluar
              </b-button>
            </section>
          </template>
        </b-modal>
        <b-modal v-model="showModalEdit" id="modal-ubah" cancel-variant="secondary" ok-title="Ubah" cancel-title="Batal"
          centered title="Form Ubah">
          <b-form>
            <b-form-group label="Provinsi" label-for="v-provinsi">
              <!-- <validation-provider #default="{ errors }" name="provinsi" :rules="{ required: true }"> -->
              <v-select v-model="form.provinsi" :reduce="(option) => option.value" label="text" :options="optprovinsi"
                @input="getkabkota()" />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
            </b-form-group>
            <b-form-group>
              <b-form-group label="Kabupaten Kota" label-for="v-kab_kota">
                <!-- <validation-provider #default="{ errors }" name="kab_kota" :rules="{ required: true }"> -->
                <v-select v-model="form.kab_kot_id" :reduce="(option) => option.value" label="text" :options="optkabko" />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
              </b-form-group>
            </b-form-group>
            <b-form-group>
              <label for="teritori">Nama Teritori</label>
              <b-form-input v-model="form.nama_teritori" label="Nama Teritori" />
            </b-form-group>
            <b-form-group>
              <label for="keterangan">Keterangan</label>
              <b-form-textarea v-model="form.keterangan" placeholder="Keterangan" rows="3" />
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="info" @click="submit">
                Ubah
              </b-button>
              <b-button size="sm" class="mr-1" variant="gradient-primary" @click="resetForm">
                Hapus isian
              </b-button>
              <b-button size="sm" variant="danger" @click="showModalEdit = false">
                Keluar
              </b-button>
            </section>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table striped small hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
          :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
          :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
          <template #cell(no)="row">
            <strong class="text-center">
              {{ (row.index + 1) }}
            </strong>
          </template>

          <template #cell(actions)="row">
            <!-- <b-dropdown
              id="dropdown-3"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              right
              class="dropdown-icon-wrapper"
            >
              <template #button-content>
                <span class="mr-1"></span>
                <feather-icon icon="ListIcon" size="16" class="align-middle" />
              </template>
              <b-dropdown-item> -->
            <b-button v-b-tooltip.hover.right="'Ubah'" v-if="allowUpdate()" size="sm" @click="edit(row.item)" class="mr-1"
              variant="outline-info">
              <feather-icon icon="EditIcon" />Ubah
            </b-button>
            <!-- </b-dropdown-item> -->
            <!-- <b-dropdown-item> -->
            <b-button v-b-tooltip.hover.right="'Hapus'" v-if="allowDelete() && row.item.anggota.length == 0" size="sm" @click="remove(row.item)" class="mr-1"
              variant="outline-danger">
              <feather-icon icon="TrashIcon" />Hapus
            </b-button>
            <!-- </b-dropdown-item>
            </b-dropdown> -->

            <!-- <b-button size="sm" @click="row.toggleDetails" class="mr-1">
              {{ row.detailsShowing ? "Hide" : "Show" }} Details 2
            </b-button> -->
          </template>
            <template #cell(kota)="data">
              <!-- <b-badge variant="primary"> -->
                {{ data.item.kota ? data.item.kota.name : '-' }}
              <!-- </b-badge> -->
            </template>
            <template #cell(anggota)="{ item }">
        <dl v-for="(items, index) in item.anggota">
          <dt>{{ items.nama_lengkap == "" ? "-" : items.nama_lengkap }}</dt>
        </dl>
            </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
          class="my-0" />
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox, BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    vSelect,
    BCardActions, ToastificationContent,
    VBTooltip,
    BModal,
    BDropdown,
    BDropdownItem,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
  BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox, BFormTextarea,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showModalEdit: false,
      showModalTambah: false,
      id: null,
      optprovinsi: [],
      optkabko: [],
      form: {
        nama_teritori: "",
        keterangan: "",
      },
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        // { key: "id", label: "ID"},
        { key: "nama_teritori", label: "Teritori", sortable: true },
        { key: "kota", label: "Kab/Kota", sortable: true },
        { key: "keterangan", label: "Keterangan" },
        { key: "anggota", label: "Anggota" },
        // { key: "sub_menu", label: "Sub Menu", sortable: true },
        // { key: "create", label: "Tambah"},
        // { key: "update", label: "Ubah"},
        // { key: "delete", label: "Hapus"},
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.getData()
    // this.getDatalevel()
    // this.getDatamenu()
    // this.getDatasubmenu()
    this.getProvinsi()

  },
  methods: {
    add() {
      this.id = null
      this.activeAction = 'tambah'
      this.showModalTambah = true
      this.showModalEdit = false
    },
    remove(item) {
      this.$swal({
        title: 'Anda yakin?',
        text: `Data teritori "${item.nama_teritori}" ini akan dihapus`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          item.fungsi = 1 // soft delete
          this.$store.dispatch('teritori/save', [item])
            .then(() => {
              this.getData()
              this.pesanBerhasilHapus()
            })
            .catch(e => {
              this.pesanGagalHapus()
              this.displayError(e)
              return false
            })
        }
      })
    },
    resetForm() {
      this.id = null
      this.form = {
        nama_teritori: 0,
        keterangan: 0,
      }
    },
    submit() {
      if (this.form.nama_teritori == null || this.form.nama_teritori == '') {
        this.pesanGagalSimpan()
        return false
      }
      if (this.id) {
        this.form.id = this.id
      }
      const payload = this.form
      this.$store.dispatch('teritori/save', [payload])
        .then(() => {
          if (this.activeAction == 'tambah') {
            this.id = null
          }
          this.getData()
          this.pesanBerhasilSimpan()
          this.resetForm()
          this.showModalTambah = false
          this.showModalEdit = false
        })
        .catch(e => console.error(e))
      // this.pesanGagalSimpan()
    },
    edit(item) {
      this.id = item.id
      this.form = item
      this.showModalEdit = true
    },
    pesanBerhasilSimpan() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Berhasil menyimpan data',
          variant: 'success',
        },
      },
        {
          position: 'bottom-right',
        })
    },
    pesanGagalSimpan() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Gagal menyimpan data, harap cek kembali data yang diisi',
          variant: 'warning',
        },
      },
        {
          position: 'bottom-right',
        })
    },
    pesanBerhasilHapus() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Berhasil menghapus data',
          variant: 'success',
        },
      },
        {
          position: 'bottom-right',
        })
    },
    pesanGagalHapus() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Gagal menghapus data, harap cek kembali data yang diisi',
          variant: 'warning',
        },
      },
        {
          position: 'bottom-right',
        })
    },
    getData() {
      this.$store.dispatch('teritori/getData', {sort: 'desc'})
        .then(() => {
          this.items = this.$store.state.teritori.datas
          this.totalRows = this.items.length;
        })
        .catch(e => console.error(e))
    },
    async getProvinsi() {
      let provinsi = await this.$store.dispatch("wilayah/getprovinsi");
      provinsi = JSON.parse(JSON.stringify(provinsi));
      let newprovinsi = [];
      provinsi.map((item) => {
        newprovinsi.push({
          text: item.name,
          value: item.id,
        });
      });
      // this.id_provinsi = newprovinsi;
      this.optprovinsi = newprovinsi;
      // });
    },
    async getkabkota() {
      const params = {
        id_provinsi: this.form.provinsi
      }
      let kabko = await this.$store.dispatch("wilayah/getkabko", params);
      kabko = JSON.parse(JSON.stringify(kabko));
      let newkabko = [];
      kabko.map((item) => {
        newkabko.push({
          text: item.name,
          value: item.id,
        });
      });
      this.optkabko = newkabko;
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
